<template>
    <div class="chat">
        <div class="chat-header">
            <div class="chat-notice">
                <span class="notice">系统消息：</span>
                <span>{{ match.room_notice }}</span>
            </div>
            <div class="chat-user">
                <div>
                    <img :src="match.avatar || match.face" alt="">
                    <span class="name">{{ match.nickname }}</span>
                </div>

                <span class="btn-chat" href="javascript:;" @click="$emit('chat')">私信主播</span>
            </div>
        </div>
        <ul class="chat-body" ref="message" @scroll="onScroll">
            <li :class="[
                    'message',
                    'message-' + msg.content_type,
                    {
                        'message-role-assistant': msg.is_assistant,
                        'message-role-manage': msg.is_manage,
                        'message-role-service': msg.is_service,
                        'message-role-anchor': msg.is_anchor,
                        'message-role-self': msg.is_self
                    }
                ]" v-for="msg in messages" :key="msg.mid">
                <span v-if="msg.content_type == 'system'"></span>
                <span class="role" v-else-if="msg.is_assistant">助理</span>
                <span class="role" v-else-if="msg.is_manage">管理员</span>
                <span class="role" v-else-if="msg.is_service">客服</span>
                <span class="role" v-else-if="msg.is_anchor">主播</span>
                <span class="role" v-else-if="msg.is_self">普通</span>
                <span class="role" v-else :style="{ backgroundColor: msg.color }">普通</span>
                <!-- <a-dropdown>
					<a-menu slot="overlay" @click="e => onMute(msg, e)">
						<a-menu-item key="forbidden">禁言用户</a-menu-item>
						<a-menu-item key="delete">删除消息</a-menu-item>
					</a-menu>
					<span class="nickname cursor" v-if="msg.role > 0 || msg.is_self">{{ msg.from_name }}：</span>
					<span class="nickname cursor" v-else :style="{ color: msg.color }">{{ msg.from_name }}：</span>
				</a-dropdown> -->
                <a-popconfirm :title="`是否禁言用户“${msg.from_name}”？`" ok-text="禁言" cancel-text="取消"
                    v-if="msg.content_type == 'txt' && msg.mutable" @confirm="onMute(msg)">
                    <span class="nickname cursor" v-if="msg.role > 0 || msg.is_self">{{ msg.from_name }}：</span>
                    <span class="nickname cursor" v-else :style="{ color: msg.color }">{{ msg.from_name }}：</span>
                </a-popconfirm>
                <span class="nickname" v-else-if="msg.content_type == 'txt' && (msg.role > 0 || msg.is_self)">{{
                    msg.from_name }}：</span>
                <span class="nickname" v-else-if="msg.content_type == 'txt'" :style="{ color: msg.color }">{{
                    msg.from_name
                }}：</span>
                <span class="message-system" v-if="msg.content_type == 'system'">{{ msg.content }}</span>
                <span class="message-content" v-else v-html="msg.html"></span>
                <!-- {{ msg.content }} -->
            </li>
            <li style="flex: 1"></li>
        </ul>


        <!-- <div class="system-message-bar" v-if="!chatroom.shield">
            <div :class="[`system`, `system-platform-${message.platform}`]" v-for="message in systemMessages"
                :key="message.mid" v-html="message.content">
            </div>
        </div> -->
        <div class="chat-editor">
            <textarea class="editor" :placeholder="match.room_status == 1 ? '请输入聊天内容~' : '系统维护中'"
                v-model="chatroom.text" @keypress.enter.exact="onSend" :resize="false" ref="textarea"
                :disabled="match.room_status != 1"></textarea>
            <div class="actions">
                <a href="javascript:;" :class="['ban', chatroom.shield ? 'active' : '']"
                    @click="chatroom.shield = !chatroom.shield"></a>
                <!-- <a href="javascript:;" class="emjio"></a> -->
                <a-popover v-model="popover" trigger="click" overlay-class-name="popover-emoticon">
                    <div slot="content" class="emoticons">
                        <div class="emoticon" v-for="e in EMOTICONS" :key="e" @click="insertEmoticon(e)">
                            <img :src="`//360bab.oss-accelerate.aliyuncs.com/emoticons/${e}.png?x-oss-process=image/quality,q_50`"
                                :alt="e" />
                        </div>
                    </div>
                    <a href="javascript:;" :class="['emjio', popover ? 'active' : '']"></a>
                </a-popover>
                <span style="flex: 1"></span>
                <a href="javascript:;" :class="['send', match.room_status == 1 && chatroom.text ? 'active' : '']"
                    @click="onSend">发送</a>
            </div>
        </div>

        <div class="more-message-bar" v-show="showMoreBar" @click="scrollBottom">
            <img class="icon" src="../assets/img/icon-down.png" />
            有新的消息
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { EMOTICONS, TEXT_COLOR } from '@/utils/constants.js';

const uColorMap = {};

export default {
    name: 'wsChatroom',
    props: {
        anchor_uid: [String, Number],
        match: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            EMOTICONS,
            uid: '',
            // toTop: 0,
            // moreMessages: false,
            // moreMessagesHeight: 0,
            mutes: [],
            muteMids: [],
            chatroom: {
                loading: false,
                shield: false,
                text: '',
                manage: [],
                assistant: [],
                service: [],
                messages: [],
                maxlength: 30
            },
            popover: false,
            systemMessages: [],
            hasMoreMessages: false,
            showMoreBar: false
        };
    },
    computed: {
        ...mapState(['token', 'minfo']),
        messages() {
            const { assistant, service, manage, messages, shield } = this.chatroom;
            const vips = [...assistant, ...service, ...manage];
            const role = vips.includes(this.minfo.uid) ? 2 : this.minfo.uid == this.uid ? 1 : 0;
            return messages
                .filter(v => {
                    v.from = parseInt(v.from);
                    return !this.muteMids.includes(v.mid) && !this.mutes.includes(v.from) && v.to == this.uid && (shield ? v.content_type == 'txt' : true);
                })
                .map(v => {
                    v.from = parseInt(v.from);
                    v.to = parseInt(v.to);
                    v.is_assistant = assistant.includes(v.from);
                    v.is_service = service.includes(v.from);
                    v.is_manage = manage.includes(v.from);
                    v.is_self = v.from == this.minfo.uid;
                    v.is_anchor = v.from == this.uid;
                    v.role = v.is_assistant || v.is_service || v.is_manage ? 2 : v.is_anchor ? 1 : 0;
                    v.mutable = role - v.role > 0;
                    uColorMap[v.from] = uColorMap[v.from] || TEXT_COLOR[Math.floor(Math.random() * TEXT_COLOR.length)];
                    v.color = uColorMap[v.from];
                    v.html = v.content
                        .replace(/</g, '&lt;')
                        .replace(/>/g, '&gt;')
                        .replace(/\[[^\[\]]*?\]/g, function ($1) {
                            if (EMOTICONS.includes($1)) {
                                return `<img class="emoticon" src="//360bab.oss-accelerate.aliyuncs.com/emoticons/${$1}.png" alt="${$1}" />`;
                            }
                            return $1;
                        });
                    return v;
                });
        },
        isManager() {
            const { assistant, service, manage } = this.chatroom;
            const muid = this.minfo.uid;
            if (muid) {
                return [this.uid, ...assistant, ...service, ...manage].includes(muid);
            }
            return false;
        }
    },
    watch: {
        // 'messages.length'() {
        //     this.$nextTick(this.scrollBottom)
        // }
        'messages.length'() {
            if (this.$refs.message) {
                const { offsetHeight, scrollHeight, scrollTop } = this.$refs.message;
                this.showMoreBar = scrollHeight > offsetHeight && offsetHeight + scrollTop + 120 < scrollHeight
                if (!this.showMoreBar) {
                    this.$nextTick(this.scrollBottom)
                }
            }
        }
    },
    created() {
        this.$bus.$on('ws.reconnect', this.getOfflineMessage);
    },
    beforeDestroy() {
        this.$bus.$off('ws.reconnect', this.getOfflineMessage);
    },
    methods: {
        // scroll(e){
        // 	 this.toTop = this.$refs.message.scrollTop;
        //     if((this.moreMessagesHeight -  this.toTop) <= 412){
        //         this.moreMessages = false;
        //     }
        // },
        init(uid) {
            if (uid) {
                this.uid = uid;
                this.enterChatroom();
            }
        },
        async getOfflineMessage() {
            if (!this.uid) {
                return;
            }
            const response = await this.$api.get(`/web/room_msg/${this.uid}`);
            if (response.code == 200) {
                const { assistant, manage, service, data } = response.data;
                this.chatroom.assistant = assistant;
                this.chatroom.manage = manage;
                this.chatroom.service = service;
                this.chatroom.messages = data.map(v => {
                    v.content_type = 'txt';
                    v.to = this.uid;
                    v.from_name = v.nickname;
                    return v;
                });
                this.$nextTick(this.scrollBottom);
            }
        },
        enterChatroom() {
            this.getOfflineMessage();
            this.$bus.$off('ws.message', this.onMessage);
            this.$bus.$on('ws.message', this.onMessage);
            this.$connect.subscribe(this.uid);
        },
        onMessage(res) {
            this.moreMessagesHeight = this.$refs.message.scrollHeight
            if (res.channel == `room-${this.uid}`) {
                if (res.event == 'room') {
                    this.chatroom.messages.push(res.data);
                }
            }
            if (res.event == 'delRoomMsg' || res.event == 'delRoomMsgNew') {
                if (res.data.uid) {
                    if (res.data.room_id) {
                        if (res.data.room_id == this.uid) {
                            this.mutes.push(parseInt(res.data.uid));
                        }
                    } else {
                        this.mutes.push(parseInt(res.data.uid));
                    }
                    // console.info('mutes', this.mutes)
                } else if (res.data.room_id == this.uid && Array.isArray(res.data.mid)) {
                    let mids = res.data.mid.map(v => parseInt(v));
                    this.muteMids.push(...mids);
                }
            }
            if (res.event == 'UnblockRoom') {
                this.mutes = this.mutes.filter(item => {
                    if (res.data.room_id && res.data.room_id == this.uid) {
                        return item != parseInt(res.data.uid)
                    }
                    return true
                })
                this.getOfflineMessage();
            }
        },
        async onSend(e) {
            e && e.preventDefault();
            if (!this.token) {
                return this.$bus.$emit('login');
            }
            const { text } = this.chatroom;
            if (text) {
                if (this.chatroom.loading) {
                    return this.$message.error('客官,说话太快啦(つД`)');
                }
                if (this.match.room_status == 1) {
                    this.chatroom.loading = true;
                    const response = await this.$api.post('/web/send_room', {
                        to: this.uid,
                        content: text,
                        content_type: 'txt'
                    }).catch(e => {
                        this.chatroom.loading = false;
                    });
                    if (response) {
                        if (response.code == 200) {
                            this.chatroom.text = '';
                        } else {
                            this.$message.error(response.msg || '消息发送失败！');
                        }
                        setTimeout(() => {
                            this.chatroom.loading = false;
                        }, 3000);
                    }

                } else {
                    return this.$message.error('系统维护中(つД`)');
                }
            }
        },
        onScroll(e) {
            const { offsetHeight, scrollHeight, scrollTop } = e.target
            if (scrollHeight > offsetHeight && offsetHeight + scrollTop + 120 < scrollHeight) {
                this.hasMoreMessages = true
            } else {
                this.hasMoreMessages = false
                this.showMoreBar = false
            }
        },
        scrollBottom() {
            this.$refs.message.scrollTo({
                top: 99999 + this.$refs.message.scrollHeight,
                behavior: 'smooth'
            });
        },
        insertEmoticon(e) {
            const el = this.$refs.textarea;
            const text = this.chatroom.text;
            const ntext = text.substring(0, el.selectionStart) + e + text.substring(el.selectionEnd);
            const n = el.selectionStart + e.length;
            this.chatroom.text = ntext;
            this.$nextTick(() => {
                this.popover = false;
                el.focus();
                el.selectionStart = n;
                el.selectionEnd = n;
            });
        },
        async onMute(msg) {
            // console.info('onMute', msg, e);
            const e = { key: 'forbidden' };
            if (msg.mutable) {
                if (e.key == 'delete') {
                    const response = await this.$api.post('/web/del_room_msg', { room_id: msg.to, member_id: msg.to, mid: msg.mid });
                    if (response && response.code == 200) {
                        // this.mutes.push(msg.from);
                        this.muteMids.push(msg.mid);
                        this.$message.success('操作成功！');
                    }
                } else if (e.key == 'forbidden') {
                    const response = await this.$api.post('/web/forbidden_speech_live_room', { room_id: msg.to, uid: msg.from });
                    if (response && response.code == 200) {
                        this.mutes.push(msg.from);
                        this.$message.success('操作成功！');
                    }
                }
            }
        }
    }
};
</script>
<style lang="less">
.popover-emoticon {
    .ant-popover-inner-content {
        padding: 4px;
    }
}

.chat-notice {
    height: 54px;
}

.chat-user {
    display: flex;
    align-items: center;
    justify-content: space-between;

    >div {
        display: flex;
        align-items: center;
    }

    .name {
        width: 80px;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    img {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        margin-right: 10px;
    }
}

.btn-chat {
    width: 100px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff7a1d;
    border-radius: 20px;
    color: #ffffff;
    cursor: pointer;
}

.nickname.cursor {
    cursor: pointer;
}

.emoticons {
    width: 320px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: #e5e5e5 1px solid;
    border-left: #e5e5e5 1px solid;

    .emoticon {
        padding: 2px;
        border-right: #e5e5e5 1px solid;
        border-bottom: #e5e5e5 1px solid;
        cursor: pointer;

        img {
            display: block;
            width: 24px;
            height: 24px;
        }
    }
}

.more-message-bar {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 4px 8px;
    right: 0;
    bottom: 140px;
    color: #ffffff;
    background: #ff7a1d;
    border-radius: 8px 0px 0px 8px;
    font-size: 12px;
    cursor: pointer;

    .icon {
        width: 14px;
        height: 14px;
        display: block;
        margin-right: 4px;
    }
}

.system-message-bar {
    background-color: #ffffff;
    pointer-events: none;
    display: flex;
    flex-direction: column-reverse;
    color: #E49013;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 10px;

    .text {
        color: #f46d2b;
        margin: 0 5px;
    }

    .system-platform-1 {
        color: #1B1BB4;

        .text {
            color: #f50;
        }
    }

}
</style>